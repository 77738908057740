import React from "react";

import Page from "lib/@cms/components/layout/Page";
import PageHeader from "lib/@cms/components/cms/PageHeader";
import ContentBox from "lib/@cms/components/shared/ContentBox";
import Button from "lib/@cms/components/primitive/Button";
import Divider from "lib/@cms/components/primitive/Divider";

function HomeLoansPage() {
  return (
    <Page pathname="home-loans">
      {data => {
        return (
          <React.Fragment>
            <PageHeader
              variant={PageHeader.variant.GRADIENTS}
              color={PageHeader.color.PRIMARY}
              data={data.PageHeaderGradients}
            />

            <div className="tw-container">
              <ContentBox>
                <ul>
                  {data.TextBodyA?.map((element, index) => {
                    return (
                      <li key={`TextBodyList-${index}`} className="tw-mb-5">
                        {element.item}
                      </li>
                    );
                  })}
                </ul>
              </ContentBox>
              <Divider className="tw-my-6" />

              <ContentBox title={data.TextBodyB.title}>
                {data.TextBodyB.description}
                <ul className="tw-list-disc tw-ml-6 tw-my-3">
                  {data.TextBodyB.textBody?.map((element, index) => {
                    return <li key={`TextBodyList-${index}`}>{element.item}</li>;
                  })}
                </ul>
              </ContentBox>
              <Divider className="tw-my-6" />

              <ContentBox title={data.TextBodyC.title}>
                {data.TextBodyC.description}
                <ul className="tw-list-disc tw-ml-6 tw-my-3">
                  {data.TextBodyC.textBody?.map((element, index) => {
                    return <li key={`TextBodyList-${index}`}>{element.item}</li>;
                  })}
                </ul>
                <Button is="a" href={data.TextBodyC.button.href} target="_blank">
                  {data.TextBodyC.button.title}
                </Button>
              </ContentBox>
              <Divider className="tw-my-6" />

              <ContentBox title={data.TextBodyD.title}>
                <p>{data.TextBodyD.description}</p>
                <ul className="tw-list-disc tw-ml-6 tw-my-3">
                  {data.TextBodyD.textBody?.map((element, index) => {
                    return <li key={`TextBodyList-${index}`}>{element.item}</li>;
                  })}
                </ul>
                <Button is="a" href={data.TextBodyD.button.href} target="_blank">
                  {data.TextBodyD.button.title}
                </Button>
              </ContentBox>
              <Divider className="tw-my-6" />

              <ContentBox title={data.TextBodyE.title}>
                <p>{data.TextBodyE.textA}</p>
                <Button is="a" href={data.TextBodyE.buttonA.href} target="_blank">
                  {data.TextBodyE.buttonA.title}
                </Button>
                <p className="tw-mt-4">{data.TextBodyE.textB}</p>
                <Button is="a" href={data.TextBodyE.buttonB.href} target="_blank">
                  {data.TextBodyE.buttonB.title}
                </Button>
                <p className="tw-mt-10">{data.TextBodyE.textC}</p>
                <p className="tw-mt-4">{data.TextBodyE.textD}</p>
              </ContentBox>
              <Divider className="tw-my-16 sm:tw-my-24" />
            </div>
          </React.Fragment>
        );
      }}
    </Page>
  );
}

export default HomeLoansPage;
